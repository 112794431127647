import React from "react";
import { useLocation } from "react-router-dom";
import { getLayout } from "../../api";

const AddGtmScript = () => {
  const location = useLocation();

  const [metaScript, setMetaScript] = React.useState('');

  React.useEffect(() => {
    const filterPath = location.pathname
      .split("/")
      .filter((part) => part !== "");

    const neoId = filterPath[0];

    const getData = async () => {
      const getRenderData = await getLayout(neoId);

      localStorage.setItem(`${neoId}-data`, JSON.stringify(getRenderData));

      if (getRenderData.retorno) {
        const metaScript = getRenderData.dados?.meta_pixel;
        if (metaScript) {
          const sanitizedScript = metaScript.replace(
            /<script.*?>|<\/script>/gi,
            ""
          );
          setMetaScript(sanitizedScript);
        }
      }
    };

    getData();
  }, [location]);

  React.useEffect(() => {
    if (!metaScript) return;

    const generateElement = () => {
      const gtmScript = document.createElement("script");
      gtmScript.async = true;
      gtmScript.innerHTML = metaScript;
      document.head.appendChild(gtmScript);
      return gtmScript;
    };

    const gtmScript = generateElement();

    return () => {
      if (gtmScript) document.head.removeChild(gtmScript);
    };
  }, [metaScript]);

  return null;
};

export default AddGtmScript;